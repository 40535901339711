.footer {
    width: 100%;
    height: 10vh;
    /* border: 1px solid blue; */
    /* background-color: #282c34; */
    background-color: #010409;
    border-top: 1px solid #262B32;
}

.footer-left-part {
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #010409;
    /* background: none; */
    /* box-shadow: inset 0 0 20px -5px red; */
}

.footer-title {
    width: 70%;
    /* height: 10vh; */
    /* color: #fff;
    font-family: 'Dosis', sans-serif; */
    /* font-family: 'Rubik', sans-serif; */
    font-size: 1.2rem;
}

.footer-mini-flag-container {
    width: 4rem;
    height: 2rem;
}

.footer-mini-flag {
    width: 100%;
    max-width: 4rem;
    height: auto;
    border-radius: 3px;
}