.navbar {
    width: 100%;
    height: 10vh;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-family: 'Dosis', sans-serif;
    background-color: #282c34;
}