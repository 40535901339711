.middle-part-gouv {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    /* background-color: #282c34; */
    overflow: hidden;
    background-color: #0D1117;
    /* background-color: #292a2b; */
    /* border: 1px solid red; */
    border-top: 1px solid #262B32;
}

.coat-container-gouv {
    width: 80%;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0D1117;
    /* border: 1px solid green; */
}

.coat-gouv {
    width: 100%;
    max-width: 400px;
    height: auto;
}