@font-face {
    font-family: "DejaVuSansMono";
    src: url('../../assets/fonts/DejaVuSansMono.ttf');
}

.middle-part-home {
    width: 100%;
    min-height: 100vh;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    background-color: #0D1117;
    font-family: DejaVuSansMono, 'Rubik', sans-serif;
    border-top: 1px solid #262B32;
}

.main-text {
    color: #E6DFBA;
    font-family: monospace;
    font-size: 24px;
}

.flag-container {
    width: 80%;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid green; */
    /* border: 1px solid #fff; */
}

.flag {
    width: 100%;
    max-width: 600px;
    height: auto;
}