@import url('https://fonts.googleapis.com/css2?family=Neucha&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');

@font-face {
    font-family: "DejaVuSansMono";
    src: url('../../assets/fonts/DejaVuSansMono.ttf');
}

.contact-middle-part {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: DejaVuSansMono;
    /* border: 1px solid red; */
    /* background-color: #282c34; */
    background-color: #0D1117;
    /* background-color: #292a2b; */
    /* border: 1px solid red; */
    border-top: 1px solid #262B32;
}

hr {
    width: 100%;
    opacity: 0.1;
    color: #262B32;
}

.contact-form {
    width: 60%;
    height: 80vh;
    padding: 1%;
    /* margin-top: -5%; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    /* background-color: #292a2b; */
    /* background-color: #282c34; */
    background-color: #0D1117;
    /* color:#afafaf; */
    /* color: #D7ECF3; */
    color: #E6DFBA;
    font-family: DejaVuSansMono, 'Rubik', sans-serif;
    /* box-shadow: 1px 1px 5px lightblue inset; */
    /* border: 1px solid green; */
}

.contact-form-input-row {
    width: 100%;
    height: 10vh;
    padding: 0%;
    display: flex;
    /* flex-direction: column; */
    justify-content: space-around;
    align-items: flex-start;
    /* border: 1px solid red; */
}

.contact-form-input-row-title {
    width: 100%;
    height: 5vh;
    padding: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #E6DFBA;
}

.contact-form-input-row-text {
    width: 100%;
    height: 25vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.contact-input-wrapper {
    /* width: 100%; */
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}

.contact-input-wrapper input {
    height: 6vh;
    width: 100%;
    outline: none;
    /* border: 1px solid hsl(0, 0%, 10%); */
    border: 1px solid #D7ECF3;
    border-radius: 5px;
    margin-top: 5%;
    color: #D7ECF3;
    padding-left: 5%;
    font-family: DejaVuSansMono;
    /* background-color: #282c34; */
    /* background-color: hsl(0, 0%, 14%); */
    background-color: #0D1117;
    /* box-shadow: 1px 1px 5px lightblue inset; */
}

.contact-input-wrapper label {
    /* color: #afafaf; */
    color: #E6DFBA;
    position: absolute;
    left: 0;
    top: 45%;
    font-size: small;
}

.contact-form-input-row-text textarea {
    height: 20vh;
    outline: none;
    /* border: 1px solid hsl(0, 0%, 10%); */
    border: 1px solid #D7ECF3;
    padding-left: 3%;
    padding-top: 3%;
    border-radius: 5px;
    /* color: #fff; */
    color: #E6DFBA;
    font-family: DejaVuSansMono;
    resize: none;
    /* background-color: hsl(0, 0%, 14%); */
    background-color: #0D1117;
    /* box-shadow: 1px 1px 5px lightblue inset; */
}

.contact-form-input-row-text label {
    color: #afafaf;
    position: absolute;
    left: 0%;
    top: 45%;
    font-size: small;
}

.contact-form-text-area {
    width: 100%;
    height: 100%;
}

.submit-btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-submit-btn {
    width: 100%;
    height: 10vh;
    outline: none;
    /* border: 1px solid hsl(0, 0%, 10%); */
    border: 1px solid #D7ECF3;
    border-radius: 5px;
    color: #E6DFBA;
    font-size: larger;
    font-family: DejaVuSansMono;
    /* background-color: #282c34; */
    /* background-color: hsl(0, 0%, 14%); */
    background-color: #0D1117;
    /* box-shadow: 1px 1px 5px lightblue inset; */
    cursor: pointer;

}



.contact-middle-part000 {
    width: 100%;
    height: 80vh;
    margin: 0%;
    padding: 0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid blue; */
    /* background-color: #282c34; */
    overflow: hidden;
}

/* 
form {
    width: 40%;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: aqua;
    border: 1px solid green;
}

.input-wrapper {
    width: 100%;
    height: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid red;
} */

.contact-middle-part .form {
    width: 50%;
    border: 1px solid yellow;
    height: 60vh;
    padding: 30px 0 0 0;
    /* border: 1px solid red; */
    background-color: #fff;
}

.contact-middle-part .form .contact-form-row {
    display: flex;
    margin: 32px 0;
}

.contact-middle-part .form h2 {
    width: 100%;
    text-align: center;
}

.form .contact-form-row .contact-input-data {
    width: 100%;
    height: 40px;
    margin: 0 20px;
    position: relative;
}

.form .contact-form-row .contact-textarea {
    height: 70px;
}

.contact-input-data input,
.contact-textarea textarea {
    display: block;
    width: 100%;
    height: 100%;
    border: none;
    font-size: 17px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.12);
}

.contact-input-data input:focus~label,
.contact-textarea textarea:focus~label,
.contact-input-data input:valid~label,
.contact-textarea textarea:valid~label {
    transform: translateY(-20px);
    font-size: 14px;
    color: #FFCC00;
}

.contact-textarea textarea {
    resize: none;
    padding-top: 10px;
}

.contact-input-data label {
    position: absolute;
    pointer-events: none;
    bottom: 50%;
    font-size: 16px;
    transition: all 0.3s ease;
}

.contact-textarea label {
    width: 100%;
    bottom: 50%;
    background: #fff;
}

.contact-input-data .contact-underline {
    position: absolute;
    bottom: 0;
    height: 2px;
    width: 100%;
}

.contact-input-data .contact-underline:before {
    position: absolute;
    content: "";
    height: 2px;
    width: 100%;
    background: #FFCC00;
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 0.3s ease;
}

.contact-input-data input:focus~.contact-underline:before,
.contact-input-data input:valid~.contact-underline:before,
.contact-textarea textarea:focus~.contact-underline:before,
.contact-textarea textarea:valid~.contact-underline:before {
    transform: scale(1);
}

.contact-submit-btn .contact-input-data {
    overflow: hidden;
    height: 45px !important;
    width: 25% !important;
}

.contact-submit-btn .contact-input-data .contact-inner {
    height: 100%;
    width: 300%;
    position: absolute;
    left: -100%;
    /* background: -webkit-linear-gradient(right, #56d8e4, #9f01ea, #56d8e4, #9f01ea);
     */
    /* background-color: #45484d; */
    /* background-color: #282c34; */
    background-color: #0D1117;
    transition: all 0.4s;
}

.contact-submit-btn .contact-input-data:hover .contact-inner {
    left: 0;
}

.contact-submit-btn .contact-input-data input {
    background: none;
    border: none;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    position: relative;
    z-index: 2;
}

@media (max-width: 700px) {
    .contact-middle-part .contact-text {
        font-size: 30px;
    }

    .contact-middle-part .form {
        padding: 10px 0 0 0;
    }

    .contact-middle-part .form .contact-form-row {
        display: block;
    }

    .form .contact-form-row .contact-input-data {
        margin: 35px 0 !important;
    }

    .contact-submit-btn .contact-input-data {
        width: 40% !important;
    }
}