@font-face {
    font-family: "DejaVuSansMono";
    src: url('../../assets/fonts/DejaVuSansMono.ttf');
}

.consti-middle-part {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    padding-top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #E6DFBA;
    font-family: DejaVuSansMono;
    font-size: 18px;
    /* border: 1px solid red; */
    /* background-color: #282c34; */
    background-color: #0D1117;
    border-top: 1px solid #262B32;
    overflow: auto;
}

.top-part {
    width: 90%;
    height: auto;
    margin-top: -5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #E6DFBA;
    font-family: DejaVuSansMono;
}

hr {
    width: 100%;
    opacity: 0.1;
    color: #D7ECF3;
}

.consti-preambule-wrapper {
    width: 90%;
    height: 35%;
    padding-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    line-height: 2rem;
    text-align: justify;
    /* border: 1px solid red; */
}

.consti-list-wrapper {
    width: 90%;
    height: 65%;
    padding: 3%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* overflow: auto; */
    /* border: 1px solid green; */
    border-radius: 2px;
    /* box-shadow: inset 1px 1px 100px white; */
}

.consti-list {
    list-style: none;
    width: 100%;
    height: 100%;
}

.consti-list-item {
    width: 100%;
    height: auto;
    padding: 1% 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: baseline;
    /* color: #fff;
    font-family: monospace;
    font-size: 18px; */
}

.article-title {
    color: #D7ECF3;
}

.consti-list-item p {
    margin-bottom: 3%;
    line-height: 2rem;
    text-align: justify;
}