@font-face {
    font-family: "DejaVuSansMono";
    src: url('../../assets/fonts/DejaVuSansMono.ttf');
}

hr {
    width: 100%;
    opacity: 0.1;
    color: #262B32;
}

.citizen-middle-part {
    width: 100%;
    height: auto;
    padding-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    /* background-color: #282c34; */
    /* background-color: #292a2b; */
    background-color: #0D1117;
    overflow: auto;
    border-top: 1px solid #262B32;
}

.citizen-form {
    width: 70%;
    height: auto;
    padding: 1%;
    margin-top: -3%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    /* background-color: #292a2b; */
    /* background-color: #282c34; */
    background-color: #0D1117;
    /* color:#afafaf; */
    color: #E6DFBA;
    /* font-family: 'Rubik', sans-serif; */
    font-family: DejaVuSansMono;
    /* box-shadow: 1px 1px 5px lightblue inset; */
    /* border: 1px solid green; */
    /* overflow: auto; */
}

.citizen-form-input-row {
    width: 100%;
    height: 6vh;
    padding: 0%;
    margin-bottom: 3%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    font-family: DejaVuSansMono;
    /* border: 1px solid red; */
}

.citizen-form-input-row-title {
    width: 100%;
    height: 10vh;
    padding: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: DejaVuSansMono;
}

.citizen-form-input-row-text {
    width: 100%;
    height: 35vh;
    margin: 5% 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    font-family: DejaVuSansMono;
}

.citizen-input-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}

.citizen-input-wrapper input {
    height: 6vh;
    width: 100%;
    outline: none;
    border: 1px solid #D7ECF3;
    border-radius: 5px;
    margin-top: 5%;
    /* color: #fff; */
    color: #D7ECF3;
    padding-left: 1%;
    /* background-color: #282c34; */
    /* background-color: hsl(0, 0%, 14%); */
    background-color: #0D1117;
    /* box-shadow: 1px 1px 5px lightblue inset; */
}

.citizen-input-wrapper label {
    /* color: #afafaf; */
    color: #E6DFBA;
    position: absolute;
    left: 0;
    top: 45%;
    font-size: small;
}

.question-row {
    /* border: 1px solid gray; */
    height: 6vh;
    margin: 5% 0;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* border: 1px solid red; */
}

/* .question-row h3 {
    padding-left: 3%;
}

.citizen-form-input-row-text h3 {
    padding-left: 3%;
} */

.citizen-form-input-row-text textarea {
    height: 20vh;
    outline: none;
    border: 1px solid #D7ECF3;
    padding-left: 3%;
    padding-top: 3%;
    margin-top: 3%;
    border-radius: 5px;
    resize: none;
    /* color: #fff; */
    color: #D7ECF3;
    /* background-color: hsl(0, 0%, 14%); */
    background-color: #0D1117;
    /* box-shadow: 1px 1px 5px lightblue inset; */
}

.citizen-form-input-row-text label {
    /* color: #afafaf; */
    color: #E6DFBA;
    position: absolute;
    left: 0%;
    top: 45%;
    font-size: small;
}

.citizen-form-text-area {
    width: 100%;
    height: 100%;
}

.submit-btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.citizen-submit-btn {
    width: 100%;
    height: 10vh;
    outline: none;
    border: 1px solid #D7ECF3;
    border-radius: 5px;
    /* color: #afafaf; */
    color: #E6DFBA;
    font-size: larger;
    /* background-color: #282c34; */
    /* background-color: hsl(0, 0%, 14%); */
    background-color: #0D1117;
    /* box-shadow: 1px 1px 5px lightblue inset; */
    cursor: pointer;

}