a {
    text-decoration: none;
    color: #E6DFBA;
}

a:visited, a:active {
    color: #E6DFBA;;
    text-decoration: none;
}

.header-left-part {
    width: 25%;
    height: 10vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #010409;
    /* background: none; */
    /* box-shadow: inset 0 0 20px -5px red; */
}

.header-title {
    width: 70%;
    /* height: 10vh; */
    /* color: #fff;
    font-family: 'Dosis', sans-serif; */
    /* font-family: 'Rubik', sans-serif; */
    font-size: 1.2rem;
}

.header-mini-flag-container {
    width: 4rem;
    height: 2rem;
}

.header-mini-flag {
    width: 100%;
    max-width: 4rem;
    height: auto;
    border-radius: 3px;
}

.header-right-part {
    width: 75%;
    height: 10vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: 'Rubik', sans-serif;
    background-color: #010409;
    /* border: 1px solid green; */
}

.header-btn-part {
    width: 50%;
    height: 10vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #010409;
    /* color: #676767; */
    /* color: #FFCC00; */
    color: #E6DFBA;
    
}

.header-nav-link {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    opacity: 0.7;
    color: #E6DFBA;
    /* transition: opacity 500ms ease; */
}

/* .header-link-icon {
    width: 9px;
    height: 9px;
    margin-left: 7px;
    margin-top: -3px;
    border-right: 2px solid #FFCC00;
    border-bottom: 2px solid #FFCC00;
    opacity: 0.7;
    border-top: 1px solid #676767;
    border-left: 1px solid #676767;
    transform: rotate(45deg);
    transition: opacity 500ms ease;

} */

.header-nav-link:hover {
    /* color: #fff; */
    opacity: 1;
}

.header-nav-link:hover .header-link-icon {
    /* border-color: #fff; */
    opacity: 1;
}